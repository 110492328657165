<template>
	<div>
		<div class="flex header">
			<h2>导入记录</h2>
			<div class="flex">
				<el-input
						placeholder="请输入关键信息"
						prefix-icon="el-icon-search"
						v-model="search"
						@change="getData">
				</el-input>
				<!--<el-button @click="downloadBatch">批量下载</el-button>-->
			</div>
		</div>
		<div class="table">
			<el-table
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 248px)"
					style="width: 100%"
					><!--@selection-change="handleSelectionChange"-->
				<!--<el-table-column
						type="selection" align="center"
						width="100">
				</el-table-column>-->
				<el-table-column
						prop="roleName" align="center" show-overflow-tooltip
						label="导入时间">
					<template slot-scope="scope">
						{{scope.row.createTime | formatTimer}}
					</template>
				</el-table-column>
				<el-table-column
						prop="createBy" align="center" show-overflow-tooltip
						label="操作人">
				</el-table-column>
				<el-table-column
						prop="fileName" align="center" show-overflow-tooltip
						label="原始导入文件">
				</el-table-column>
				<el-table-column
						align="center" show-overflow-tooltip
						label="状态">
					<template slot-scope="scope">
						{{scope.row.state == 0 ? '未完成' : scope.row.state == 1 ? '已完成' : '导入失败'}}
					</template>
				</el-table-column>
				<el-table-column
						prop="errorMsg" align="center" show-overflow-tooltip
						label="失败原因">
				</el-table-column>
				<el-table-column align="center"
												 label="操作" width="200px">
					<template slot-scope="scope">
						<el-button class="update" @click="handleDownLoad(scope.$index, scope.row)">文件下载</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>
	</div>

</template>

<script>
import { getImportRecordList,downLoadRecordFile } from "@/api/api";

export default {
	name: "importRecords",
	data() {
		return {
			search: "",
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			records:[]
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			let params = {
				page: this.page.current,
				size: this.page.size,
				key:this.search
			};
			getImportRecordList(JSON.stringify(params)).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
			}).catch(err => {
				console.log("err: " + err);
			});
		},
		handleDownLoad(index, row) {
			downLoadRecordFile(row.fileName,row.id)
		},
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		/*handleSelectionChange(val){
			this.records = val;
		},*/
		downloadBatch(){
			if (this.records.length<=0){
				this.$message.error("请先选择要下载的文件")
				return;
			}
			let ids = this.records.map(item=>{return item.id})
			downLoadRecordFile(null,ids)
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
